export const ActionTypes = {
        SET_MODE: 'SET_MODE',
        SET_THEME: 'SET_THEME',
        SET_THEME_HOVER: 'SET_THEME_HOVER',
        FETCH_DATA: 'FETCH_DATA',
        SELECT_PRODUCT: 'SELECT_PRODUCT',
        REMOVE_SELECTED_PRODUCT: 'REMOVE_SELECTED_PRODUCT',
        SELECT: 'SELECT',
        SET_LANGUAGE: 'SET_LANGUAGE'

}